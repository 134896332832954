import React, { Component } from "react"
//import ReactDOM from "react-dom"
//import { Link } from "gatsby"

import Layout from "../../components/layout"

import SEO from "../../components/seo"
import { PageHeader, BannerHeader, BannerImage, styles } from "../../utils"
import gamesImg from "../../images/BGgamepads28.png"
import { Link } from "gatsby"
import AboutImg from "../../images/Banner.png"
import styled from "styled-components"
import JellyBotsInfo from "../../components/HomePageComponents/JellyBotsInfo"
import IFrame from "../../components/iframe"
const sizes = [
  { width: "768px", height: "432px", name: "small" },
  { width: "1152px", height: "648px", name: "medium" },
  { width: "1536px", height: "864px", name: "large" },
]
class JellyBotsPage extends Component {
  constructor(props) {
    super(props)
    this.state = { gameSize: sizes[0] }
  }
  handleClick = value => {
    this.setState({ gameSize: value })
    console.log(value)
  }

  render() {
    return (
      <Layout>
        <SEO title="Jellybots" />
        <BannerHeader>
          <Link
            to="https://bit.ly/3QJ35C5"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <BannerImage>
              <img src={AboutImg} alt="Super Choppy Orc"></img>
            </BannerImage>
          </Link>
        </BannerHeader>

        <PageHeader img={gamesImg}>
          <IFrame
            sizes={sizes}
            src="https://compassionate-shirley-c820a0.netlify.com/"
            handleSizeChange={this.handleClick}
            gameSize={this.state.gameSize}
          />
        </PageHeader>
        <JellyBotsInfo />
      </Layout>
    )
  }
}

export default JellyBotsPage
